import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Subject, timer } from "rxjs";
import { repeat, retryWhen, switchMap, takeWhile, tap } from "rxjs/operators";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { environment as env } from "src/environments/environment";
import { WorkflowMessage } from "../workflows/workflow-message-handler/workflow-handler.const";

const MAX_ATTEMPTS = 5;

@Injectable({ providedIn: 'root'})
export class NotificationsService {

    onMessage$ = new Subject<WorkflowMessage>();

    private _webSocket$: WebSocketSubject<WorkflowMessage>;

    connectionId: string;

    destroyRef = inject(DestroyRef);

    attempts = 0;

    start(): void {
        this._webSocket$ = webSocket({
            url:env.api.notification,
            openObserver: {
                next: () => { 
                    console.log('Websocket connection established');
                    this.sendConnectionIdRequest();
                }
            },
            closeObserver: {
                next: () => console.log('Websocket connection closed, retrying...')
            },
        });


        this._webSocket$
        .pipe(
            takeUntilDestroyed(this.destroyRef),
            retryWhen<WorkflowMessage>(errors => 
                { 
                    console.log('error?')
                    return errors.pipe(
                        tap(error => console.error('Websocket error:', error)),
                        switchMap(() => { 
                            console.log('retrying...', this.attempts + 1, 'of', MAX_ATTEMPTS);
                            return timer(5000)
                        }),
                        repeat(),
                        takeWhile(() => ++this.attempts < MAX_ATTEMPTS)
                    )
            })
        ).subscribe((message: WorkflowMessage) => {
            if (message.connectionId && !message.context) {
                this.connectionId = message.connectionId;
                return;
            }

            this.onMessage$.next(message);
        });
    }

    sendConnectionIdRequest(): void {
        this._webSocket$.next({ } as any);
    }
}