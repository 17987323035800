import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notification.service';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagInterceptor implements HttpInterceptor {

  featureFlagService = inject(FeatureFlagService);

  notificationService = inject(NotificationsService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.shouldIntercept(request)
      .pipe(
        switchMap((shouldIntercept) => {
          if (shouldIntercept) {
            const changedRequest = this.handleRequest(request);

            request = changedRequest.clone({
              setHeaders: {
                connectionId: this.notificationService.connectionId
              }
            });
          }
          return next.handle(request);
        })
      );
  }

  handleRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone();
  }

  // Must be implemented in child classes
  shouldIntercept(request: HttpRequest<unknown>): Observable<boolean> {
    return of(false);
  }
}
