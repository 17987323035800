export enum ProductRecordStatus {
  DRAFT = 'Draft',
  IN_REVIEW = 'In Review',
  PARTIALLY_COMPLETE = 'Partially Complete',
  COMPLETE = 'Complete',
  DELETED = 'Deleted',
}

export const STATUS_TYPE: {id: ProductRecordStatus, displayValue: string}[] = [
  { id: ProductRecordStatus.DRAFT, displayValue: 'Draft' },
  { id: ProductRecordStatus.IN_REVIEW, displayValue: 'In Review' },
  { id: ProductRecordStatus.PARTIALLY_COMPLETE, displayValue: 'Partially Complete' },
  { id: ProductRecordStatus.COMPLETE, displayValue: 'Complete' },
  { id: ProductRecordStatus.DELETED, displayValue: 'Deleted' },
];
