import { AuthService } from './auth.service';

import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private authService: AuthService;

  constructor(private httpClient: HttpClient, private injector: Injector) {}

  get<T>(path: string, apiRoot: string): Observable<T> {
    return this.httpClient.get<T>(apiRoot + path, this.getOptions());
  }

  getWithOpts<T>(
    path: string,
    apiRoot: string,
    options: any
  ): Observable<HttpEvent<T>> {
    return this.httpClient.get<T>(apiRoot + path, options);
  }

  getWithParams<T>(
    path: string,
    params: any,
    apiRoot: string,
    responseType = 'json'
  ): Observable<T> {
    return this.httpClient.get<T>(apiRoot + path, {
      ...this.getOptions(),
      params,
      responseType: responseType as any,
    });
  }

  getWithParamsWithHeaders(
    path: string,
    params: any,
    apiRoot: string
  ): Observable<any> {
    return this.httpClient.get(apiRoot + path, {
      headers: this.getOptions().headers,
      observe: 'response',
      reportProgress: true,
    });
  }

  delete<T>(path: string, body: any, apiRoot: string): Observable<T> {
    return this.httpClient.delete<T>(
      apiRoot + path,
      this.getDeleteOptions(body)
    );
  }

  deleteNoBody<T>(path: string, apiRoot: string): Observable<T> {
    return this.httpClient.delete<T>(apiRoot + path, this.getOptions());
  }

  post<T, R>(path: string, payload: T, apiRoot: string): Observable<R> {
    return this.httpClient.post<R>(apiRoot + path, payload, this.getOptions());
  }

  postWithHeaders<T, R>(
    path: string,
    payload: T,
    apiRoot: string,
    headers: any
  ): Observable<R> {
    return this.httpClient.post<R>(apiRoot + path, payload, {
      headers: {
        ...this.getOptions().headers,
        ...headers,
      },
    });
  }

  put<T, R>(path: string, payload: T, apiRoot: string): Observable<R> {
    return this.httpClient.put<R>(apiRoot + path, payload, this.getOptions());
  }

  putWithHeaders<T, R>(
    path: string,
    payload: T,
    apiRoot: string,
    headers: any
  ): Observable<R> {
    return this.httpClient.put<R>(apiRoot + path, payload, {
      headers: {
        ...this.getOptions().headers,
        ...headers,
      },
    });
  }

  patch<T, R>(path: string, payload: T, apiRoot: string): Observable<R> {
    return this.httpClient.patch<R>(apiRoot + path, payload, this.getOptions());
  }

  public getDeleteOptions(body) {
    return {
      ...this.getOptions(),
      body,
    };
  }

  public getOptions() {
    if (!this.authService) {
      this.authService = this.injector.get(AuthService);
    }

    const { jwtToken } =
      this.authService.userSessionSubject.value?.idToken || {};

    return {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'id-token': '' + jwtToken,
        id_token: '' + jwtToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
  }
}
