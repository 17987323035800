import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class GlobalUnauthorizedErrorHandlerInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                const { status } = error;

                if (status === 0) {
                    console.error(error);
                    return throwError(({ error: { message: 'An unexpected error occurred - Please check your internet connection or CORS settings.' } }));
                }

                if (status === 401) {
                    console.error(error);
                    return throwError(({ error: { message: 'Unauthorized - You don\'t have permission to access this resource' } }));
                }

                return throwError(error);
            })
        );
    }
}