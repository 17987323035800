

<ng-container *ngIf="!authService.isAuthenticated()">
  <div class="loginWrap">
    <div class="bgImg"></div>
    <div class="logoSpacer">
      <div class="loginLogoContainer">
        <div class="loginLogo">
          <img
            src="/assets/inspire-white-magenta.svg"
            alt="Inspire Logo"
            height="67"
            width="240"
          />
        </div>

        <amplify-authenticator hideSignUp="true" [formFields]="formFields">
          <ng-template amplifySlot="sign-in-header">
            <h3
              class="amplify-heading logIn"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Log in
            </h3>
          </ng-template>
             <ng-template amplifySlot="sign-in-footer" style="display: contents">
            <div data-amplify-footer>
              <button
                amplify-button
                (click)="authenticator.toResetPassword()"
                fontweight="normal"
                size="small"
                variation="link"
                fullwidth="true"
                type="button"
                data-fullwidth="true"
                data-size="small"
                data-variation="link"
                class="
                  amplify-button
                  amplify-button--fullwidth
                  amplify-button--link
                  amplify-button--small
                "
                style="font-weight: normal"
              >
                Forgot your password?
              </button>

              <div class="sso-amplify" *ngFor="let sso of identityProviders">
                <button
                  (click)="
                    handleSignInClick(sso)
                  "
                  class="
                    amplify-button
                    amplify-button--fullwidth
                    amplify-button--medium
                    amplify-button--primary
                  "
                  data-fullwidth="false"
                  data-size="small"
                  type="button"
                  style="font-weight: normal"
                >
                  Sign in with {{ sso | uppercase }}
                </button>
              </div>
            </div>
          </ng-template>
          <ng-template amplifySlot="setup-totp-header">
            <h3
              class="amplify-heading totp"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Setup authentication
            </h3>
            <p>Please scan the QR code with your authenticator app</p>
          </ng-template>
          <ng-template amplifySlot="confirm-sign-in-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Confirm authenticator code
            </h3>
            <p>Please enter the six digit code from your authenticator app</p>
          </ng-template>
          <ng-template amplifySlot="confirm-sign-up-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Please set a new password
            </h3>
          </ng-template>
          <ng-template amplifySlot="sign-up-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Please set a new password
            </h3>
          </ng-template>
          <ng-template amplifySlot="reset-password-header">
            <h3
              class="amplify-heading forgotPwd"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Password reset
            </h3>
            <p>
              Please enter your username and click 'Send Code' to reset your
              password
            </p>
          </ng-template>
          <ng-template amplifySlot="confirm-reset-password-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Reset password
            </h3>
            <p>
              Please enter the code sent to your registered email address and set a new password
            </p>
          </ng-template>
        </amplify-authenticator>
        <div class="footer">
          <!-- <p>
            Need help?<br />
            <a
              href="mailto:inspire@deltacapita.com?subject=Website%20Support%20Query"
              target="_blank"
              class="contact-team"
              >Get in touch</a
            >
            with our team.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="authService.isAuthenticated()">
  <app-navbar
    (signOutEmitter)="authService.signOut()"
    (loadedEmitter)="onLoaded()"
    [email]="email"
    [loggedInAsDistributor]="loggedInAsDistributor"
    [user]="user"
  >
  </app-navbar>

  <ng-container *ngIf="loading; else content">
    <app-dc-loading>
      Initializing...
    </app-dc-loading>
  </ng-container>
  <ng-template #content>
    <ng-container>
      <router-outlet></router-outlet>
    </ng-container>
  </ng-template>

  <div class="app-version">
    <div class="environment">
      {{ environment }}
    </div>
    Build Number: {{ appBuildNumber }}<br />
    Build Date: {{ appBuildDateString }}<br />
    Version: {{ appVersion.substring(0, 10) }}<br />
  </div>
</ng-container>
