import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Provider } from "@angular/core";
import { AnalyticsFeatureInterceptor } from "./analytics-feature.interceptor";
import { DocGenFeatureInterceptor } from "./doc-gen-feature.interceptor";
import { GlobalUnauthorizedErrorHandlerInterceptor } from "./global-unauthorized-handler.interceptor";
import { GovernanceReportsInterceptor } from "./governance-reports.interceptor";

export const featureInterceptors: Provider[] = [
    { provide: HTTP_INTERCEPTORS, useClass: DocGenFeatureInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AnalyticsFeatureInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GovernanceReportsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalUnauthorizedErrorHandlerInterceptor, multi: true },
];