import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpMethod } from "src/app/common-types/common-types.consts";
import { environment } from "src/environments/environment";
import { FeatureFlagInterceptor } from "../feature-flag.interceptor";
import { FeatureKeys } from "../feature-flag.model";

@Injectable()
export class DocGenFeatureInterceptor extends FeatureFlagInterceptor {

    shouldIntercept(request: any): Observable<boolean> {
        const isDocGen = request.url.includes(environment.api.docgen);
        const isTMG = request.body?.subtype === 'TMG';
        const isGovernanceReport = request.url.includes('/GOVERNANCE_REPORT');

        if (!isDocGen || isGovernanceReport || isTMG || (isDocGen && request.method !== HttpMethod.POST)) {
            return of(false)
        }

        return this.featureFlagService.getFeatureFlag(FeatureKeys.DOC_GEN)
            .pipe(
                map(({ isEnabled }) => !!isEnabled)
            )
    }

    handleRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
        return request.clone({
            url: request.url.replace(environment.api.docgen, `${environment.workflow.api.workflowEngine}/api/docgen/generate`),
            setParams: {
                language: request.body['language']
            }
        })
    }
}